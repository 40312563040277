<template>
  <div>
    <nav-alert></nav-alert>

    <b-navbar toggleable="lg" type="dark" class="mb-nav">

      <b-navbar-toggle target="nav-collapse" class="mb-nav-collapse"></b-navbar-toggle>

      <b-navbar-brand class="mb-nav-logo">
        <router-link to="/">
          <b-img fluid :src="require('@/assets/images/logo/' + $t('safeometer_logo'))" alt="logo"/>
        </router-link>
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav ref="navcollapse">

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto mb-nav-right">

          <b-nav-item to="/test" class="mb-nav-item" right>
            {{ $t('global.safeometer_takeTest') }}
          </b-nav-item>
          <b-nav-item to="/contact" class="mb-nav-item" right>
            {{ $t('global.safeometer_helplines') }}
          </b-nav-item>
          <b-nav-item-dropdown ref="droplocalization" class="mb-nav-item mb-nav-localization" :text="$t('global.safeometer_language')" right>
            <template slot="button-content">
              <span>{{ $t('global.safeometer_language') }}</span>
              <b-img :src="require('@/assets/images/icons/localization.svg')" alt="Localization"/>
            </template>

            <b-dropdown-item
                href="#"
                v-for="languaje in languages"
                :key="languaje"
                @click="switchLocale(languaje.ISO_639_1)"
            >
              {{languaje.name}}
            </b-dropdown-item>

          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
      <b-nav-item :class="layout.desktop + ' mb-nav-item mb-nav-item-logo-1 mb-nav-spotlight-text'">
<!--        <b-img :src="require('@/assets/images/spotlight_logo/' + $t('spotlight_logo'))" alt="Spotlight logo"/>-->
        {{ $t('safeometer_spotlight_text') }}
      </b-nav-item>
      <b-nav-item :class="layout.desktop + ' mb-nav-item mb-nav-item-logo-2'">
        <b-img :src="require('@/assets/images/biz_logo/' + $t('biz_logo'))" alt="UNDP logo"/>
      </b-nav-item>

      <b-nav-item to="/test" :class="layout.mobile + ' mb-nav-item mb-nav-item-icon mb-nav-item-icon-thermometer'">
        <b-img :src="require('@/assets/images/icons/thermometer.svg')" alt="Thermometer"/>
      </b-nav-item>
      <b-nav-item to="/contact" :class="layout.mobile + ' mb-nav-item mb-nav-item-icon mb-nav-item-icon-telephone'">
        <b-img :src="require('@/assets/images/icons/telephone.svg')" alt="Telephone"/>
      </b-nav-item>
      <b-nav-item @click="showLangSelector" :class="layout.mobile + ' mb-nav-item mb-nav-item-icon mb-nav-item-icon-localization'">
        <b-img :src="require('@/assets/images/icons/localization.svg')" alt="Localization"/>
      </b-nav-item>

    </b-navbar>

  </div>
</template>

<script>
import { BNavbar } from 'bootstrap-vue';
import { BImg } from 'bootstrap-vue';
import NavAlert from "@/components/NavAlert";

export default {
  name: "Nav",
  components:{
    'b-navbar': BNavbar,
    'b-img': BImg,
    NavAlert
  },
  data(){
    return {
      layout: this.$responsiveLayout,
      languages: []
    }
  },
  mounted() {
    let translations = Object.keys(this.$i18n.messages).filter(item => item !== 'template');

    translations.forEach(tr => {
      let translation = require('../locales/' + tr + '.json')
      this.languages.push(
          {
            name: translation['safeometer_languageName'],
            ISO_639_1: tr
          }
      );
    });

    document.title = this.$t('safeometer_languagesLinks').replace("www.", "").replace(".org", "")

  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        localStorage.setItem("lang", locale);
      }
      if(locale === 'ar'){
        document.body.classList.add('mb-arabic');
      }else{
        document.body.classList.remove('mb-arabic');
      }
      document.title = this.$t('safeometer_languagesLinks').replace("www.", "").replace(".org", "")
    },
    showLangSelector(){
      this.$refs.navcollapse.show = true;
      this.$refs.droplocalization.show();
    }
  }
}
</script>

<style scoped>
  .mb-nav{
    background-color: #0468B1;
    min-height: 83px;
  }

  ::v-deep .nav-link .mb-nav-item-dropdown-btn-localization {
    color: #FFFFFF;
  }

  .mb-nav-localization {
    margin-right: 5px;
  }

  .mb-nav-localization span {
    margin-right: 5px;
  }

  .mb-arabic .mb-nav-localization {
    margin-right: 0;
    margin-left: 5px;
  }

  .mb-arabic .mb-nav-localization span {
    margin-right: 0;
    margin-left: 5px;
  }

  ::v-deep .mb-nav-localization  .dropdown-toggle::after{
    color: #FFFFFF;
  }

  .mb-nav-localization .dropdown-toggle img {
    height: 20px;
  }

  .mb-nav-logo{
    width: 288px;
  }

  .mb-nav-item-icon {
    margin-right: 5px;
    height: 36px;
    width: 36px;
  }

  .mb-nav-item-icon-localization {
    margin-right: 7px;
  }

  .mb-nav-item-icon-thermometer {
    margin-left: auto;
  }

  .mb-arabic .mb-nav-item-icon-thermometer {
    margin-left: 0;
    margin-right: auto;
  }

  .mb-nav-item-icon a {
    padding: 0;
  }

  .mb-nav-spotlight-text {

    font-family: "Nunito Semibold";
    align-items: center;
    text-align: right;
    line-height: 14px;
    max-width: 90px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .mb-nav-spotlight-text .nav-link {
  }

  @media (min-width: 991.99px){
    .mb-nav-spotlight-text {
      display: inline-flex !important;
    }
  }

  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-nav{
      min-height: 55px;
      margin-top: 45px;
    }
    .mb-nav-logo{
      padding: 0;
      width: auto;
    }

    .mb-nav-logo a {
      padding: 0;
      margin: 0;
    }

    .mb-nav-logo img {
      height: 28px;
    }
    .navbar {
      padding: .5rem .5rem;
    }
    .navbar-toggler {
      padding: 0;
    }

    .mb-nav-localization {
      padding-bottom: 15px;
    }
  }


  .mb-nav-collapse{
    border-width: 0;
  }
  ::v-deep .nav-link span{
    color: #FFFFFF;
  }
  .mb-nav-item .nav-link,
  .navbar-dark .navbar-nav .nav-link{
    color: #FFFFFF;
  }
  .mb-nav-item-logo-1,
  .mb-nav-item-logo-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    list-style-type: none;
  }
  .mb-nav-item-logo-2 {
    margin-right: -1rem;
  }

  .mb-arabic .mb-nav-item-logo-2 {
    margin-left: 0rem;
    margin-right: 0;
  }

  .mb-nav-item-logo-1,
  .mb-nav-item-logo-2 {
    height: 83px;
  }

  .mb-nav-item-logo-1 a,
  .mb-nav-item-logo-2 a {
    padding: 0;
  }

  .mb-nav-item-logo-1 img,
  .mb-nav-item-logo-2 img{
    height: 83px;
  }

  .navbar{
    justify-content: unset;
    padding-left: 5px;
  }

  .navbar.navbar-toggler{
    border-width: 0;
  }

  ::v-deep .navbar .navbar-toggler-icon {
    background-image: url("~@/assets/images/icons/hamburguer.svg");
    margin: 10px 10px;
    height: 20px;
    width: 20px;
  }
</style>