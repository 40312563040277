<template>
  <div class="mb-contact-phone">
    <div class="mb-contact-phone-label" v-bind:style="{ 'background-color': bgLabelComputed }">
      {{ label }}
    </div>
    <div class="mb-contact-phone-number" v-bind:style="{ 'background-color': bgPhoneComputed }"
         v-html="generateHTMLPhone(phone)"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPhone",
  props: ["label", "phone", "bglabel", "bgPhone"],
  methods: {
    generateHTMLPhone(phoneString){
      let phones = phoneString.split("-");
      let phonesHtml = [];
      phones.forEach(phone => {
        phonesHtml.push(
            `<a class="mb-contact-phone-number-link" style="color: white; text-decoration: none;" href="tel:${ phone.trim() }">${ phone.trim() }</a>`
        );
      });
      return phonesHtml.join(" - ");
    }
  },
  computed:{
    bgLabelComputed(){
      return this.bglabel;
    },
    bgPhoneComputed(){
      return this.bgPhone;
    }
  }
}
</script>

<style scoped>
  .mb-contact-phone{
    border: 1px solid #0468B1;
  }
  .mb-contact-phone-number {
    background-color: #0468B1;
    padding: 5px 0;
    font-size: 25px;
    line-height: 30px;
    color: white;
  }
  .mb-contact-phone-label {
    padding: 10px 0 10px 0;
    line-height: 20px;
    font-size: 24px;
    color:  #0468B1;
  }
  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-contact-phone-label {
      font-size: 16px;
      line-height: 20px;
    }
    .mb-contact-phone-number {
      padding: 3px 0;
      font-size: 22px;
      line-height: 27px;
    }
  }

  .mb-contact-phone-number-link,
  .mb-contact-phone-number-link:hover{
    color: white;
  }
  .mb-contact-phone-number a {
    color: white;
  }

</style>