<template>
  <div>
    <div class="mb-footer">
      <router-link class="mb-footer-link" to="/terms" >{{ $t('safeometer_termsOfUse') }}</router-link>
      <router-link class="mb-footer-link" to="/privacy" >{{ $t('safeometer_privacy') }}</router-link>
      <a class="mb-footer-link copyright" href="#">© 2020 Safeometer</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
  .mb-footer{
    background-color: #D7F2F9;
    position: relative;
    height: 25px;
    width: 100%;
    bottom: 0;
    right: 0;
    bottom:0;
    left:0;
  }

  .mb-footer-link{
    font-family: Nunito Semibold;
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    margin-right: 1em;
    cursor: pointer;
    color: #0468B1;
  }

  .mb-arabic .mb-footer-link {
    margin-left: 1em;
    margin-right: 0;
  }

  .mb-arabic .mb-footer-link:last-of-type {
    margin-left: 0;
  }

  .mb-footer-link:last-of-type{
    margin-right: 0;
  }
  .copyright {
    text-decoration: none;
    cursor: default;
  }
</style>