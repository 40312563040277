import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: localStorage.getItem("lang") === null ? process.env.VUE_APP_I18N_LOCALE : localStorage.getItem("lang"),
  fallbackLocale: localStorage.getItem("lang") === null ? process.env.VUE_APP_I18N_FALLBACK_LOCALE : localStorage.getItem("lang"),
  messages: loadLocaleMessages()
})

if(localStorage.getItem("lang") === 'ar'){
  document.body.classList.add('mb-arabic');
}else{
  document.body.classList.remove('mb-arabic');
}

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })