<template>
  <div class="home">
    <home-content-header></home-content-header>
    <div class="mb-home-content">

      <b-container :class="layout.desktop">

        <b-row class="mb-home-content-row">

          <b-col cols="6" class="mb-home-content-illustration">
            <b-img fluid :src="require('@/assets/images/illustrations_web/' + $t('home.safeometer_illustration_women'))" alt="Illustration" />
          </b-col>

          <b-col cols="6" class="mb-home-content-body">
            <h1 class="mb-home-content-body-title">{{ $t('home.safeometer_title') }}</h1>
            <p class="mb-home-content-body-p1">{{ $t('home.safeometer_home_description1') }}</p>
            <p class="mb-home-content-body-p2">{{ $t('home.safeometer_home_description2') }}</p>
            <b-button to="/test" class="mb-home-content-body-btn">
              {{ $t('global.safeometer_takeTest') }}
            </b-button>
          </b-col>

        </b-row>

      </b-container>

      <b-container :class="layout.mobile">

        <b-row class="mb-home-content-row mb-home-content-row-mobile">

          <b-col cols="12" class="mb-home-content-illustration">
            <b-img fluid :src="require('@/assets/images/illustrations_mobile/' + $t('home.safeometer_illustration_women_mobile'))" alt="Illustration" />
          </b-col>

          <b-col cols="12" class="mb-home-content-body">
            <h1 class="mb-home-content-body-title">{{ $t('home.safeometer_title') }}</h1>
            <p class="mb-home-content-body-p1">{{ $t('home.safeometer_home_description1') }}</p>
            <p class="mb-home-content-body-p2">{{ $t('home.safeometer_home_description2') }}</p>
            <b-button to="/test" class="mb-home-content-body-btn">
              {{ $t('global.safeometer_takeTest') }}
            </b-button>
          </b-col>

        </b-row>

      </b-container>

      <div class="mb-home-content-help">
        <div class="mb-home-content-help-title">
          {{ $t('home.safeometer_home_needHelp') }}
        </div>
        <div :class="layout.desktop + ' mb-home-content-help-msg'">
          {{ $t('home.safeometer_home_needHelp_description') }}
        </div>
        <div :class="layout.mobile + ' mb-home-content-help-msg'">
          {{ $t('home.safeometer_home_needHelp_description') }}
        </div>
        <b-button  to="/contact" class="mb-home-content-help-btn">
          <img src="@/assets/images/icons/telephone_blue.svg" alt="Telephone">{{ $t('global.safeometer_helplines') }}
        </b-button>
      </div>

      <div :class="layout.desktop + ' mb-home-content-video'">
        <div class="mb-home-content-video-video">
<!--          <video width="100%" controls>-->
<!--            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">-->
<!--          </video>-->
          <iframe
              width="560" height="315"
              src="https://www.youtube-nocookie.com/embed/cXAXBC9PPkY"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
          >
          </iframe>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HomeContentHeader from "@/components/HomeContentHeader";
import { BContainer } from 'bootstrap-vue';
import { BCol } from 'bootstrap-vue';
import { BButton } from 'bootstrap-vue';
import { BImg } from 'bootstrap-vue';

export default {
  name: 'Home',
  components: {
    HomeContentHeader,
    'b-container': BContainer,
    'b-button': BButton,
    'b-col': BCol,
    'b-img': BImg
  },
  data () {
    return {
      layout: this.$responsiveLayout
    }
  }
}
</script>
<style>
  .mb-home-content-body,
  .mb-home-content-illustration{
    padding: 0;
  }
  .mb-home-content-body > img,
  .mb-home-content-illustration > img{
    margin-right: auto;
    margin-left: auto;
  }
  .mb-home-content-row {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .mb-home-content-row.mb-home-content-row-mobile {
    padding-top: 0;
  }
  .mb-home-content-body-title {
    font-family: Lora;
    font-weight: bold;
    line-height: 45px;
    font-size: 35px;
    color: #0468B1;
  }
  .mb-home-content-body-p1 {
    color: #0468B1;
  }
  .mb-home-content-body-p2 {
    color: #48494A;
  }
  .mb-home-content-body-p1,
  .mb-home-content-body-p2 {
    font-family: Nunito Semibold;
    line-height: 20px;
    font-size: 18px;
  }
  .mb-home-content-body {
    padding-left: 50px;
    text-align: left;
  }
  .mb-home-content-row-mobile .mb-home-content-body {
    text-align: center;
    padding: 10px 10px 0 10px;
  }
  .mb-home-content-body-btn,
  .mb-home-content-body-btn:hover {
    background-color: #FF5054;
    border-color: #FF5054;
  }
  .mb-home-content-body-btn {
    font-family: Lora;
    font-weight: bold;
    border-radius: 20px;
    line-height: 28px;
    font-size: 22px;
    padding: 12px 80px 12px 80px;
  }
  .mb-home-content-help{
    background-color: #0468B1;
    color: #FFFFFF;
  }
  .mb-home-content-help-title{
    font-family: Lora;
    font-weight: bold;
    line-height: 45px;
    font-size: 35px;
    padding-top: 23px;
  }
  .mb-home-content-help-msg{
    font-family: Nunito Semibold;
    margin: 10px auto 15px auto;
    line-height: 20px;
    font-size: 18px;
    width: 55%;
  }

  .mb-layout-mobile.mb-home-content-help-msg {
    width: 90%;
  }

  .mb-home-content-help-btn,
  .mb-home-content-help-btn:hover{
    background-color: #F9E069;
    border-color: #F9E069;
    color: #0468B1;
  }
  .mb-home-content-help-btn{
    font-family: Lora;
    vertical-align: middle;
    padding: 11px 1.5em 11px 1.5em;
    font-weight: bold;
    border-radius: 20px;
    margin-bottom: 15px;
    line-height: 28px;
    font-size: 22px;
  }
  .mb-home-content-help-btn > img {
    margin-right: 0.5em;
    height: 30px;
  }
  .mb-arabic .mb-home-content-help-btn > img {
    margin-left: 0.5em;
    margin-right: 0;
  }
  .mb-home-content-video-video {
    margin: 15px auto 10px auto;
    color: #FFFFFF;
    width: 585px;
  }
  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-home-content-body-title{
      line-height: 36px;
      font-size: 28px;
    }
    .mb-home-content-body-p1,
    .mb-home-content-body-p2 {
      line-height: 18px;
      font-size: 16px;
    }
    .mb-home-content-body-btn {
      padding: 5px 54px 5px 54px;
      line-height: 23px;
      font-size: 18px;
    }
    .mb-home-content-help-title {
      padding-top: 14px;
      line-height: 36px;
      font-size: 28px;
    }
    .mb-home-content-help-msg {
      line-height: 18px;
      font-size: 16px;
    }
    .mb-home-content-help-btn {
      padding: 3px 1.5em 3px 1.5em;
      line-height: 23px;
      font-size: 18px;
    }
  }


</style>
