<template>
  <b-container class="mb-nav-alert-container" fluid>
    <b-row class="mb-nav-alert-row">
      <b-col class="mb-no-padding mb-nav-alert d-none d-sm-none d-md-none d-lg-block d-xl-block" cols="12" v-html="$t(
              'global.safeometer_securityWarning',
              {
                linkPrivacy: this.$t('safeometer_externalLInk_privacy'),
                linkHistory: this.$t('safeometer_externalLInk_deleteHistory'),
                linkClassPrivacy:'mb-link',
                linkClassHistory:'mb-link'
              }
          )" >
      </b-col>
      <b-col class="mb-no-padding d-block d-sm-block d-md-block d-lg-none d-xl-none" cols="12">
        <div class="mb-nav-alert mb-nav-alert-mobile">
          <div class="mb-nav-alert-mobile-content" v-html="$t(
              'global.safeometer_securityWarning',
              {
                linkPrivacy: this.$t('safeometer_externalLInk_privacy'),
                linkHistory: this.$t('safeometer_externalLInk_deleteHistory'),
                linkClassPrivacy:'mb-link',
                linkClassHistory:'mb-link'
              }
          )"></div>
        </div>
<!--        <b-img class="mb-nav-alert-spotlight" :src="require('@/assets/images/spotlight_logo/' + $t('spotlight_logo'))" alt="Illustration" />-->
        <div class="mb-nav-alert-spotlight-text">
          {{ $t('safeometer_spotlight_text') }}
        </div>

        <b-img class="mb-nav-alert-biz" :src="require('@/assets/images/biz_logo/' + $t('biz_logo').replace('.', '_movil.'))" alt="Illustration" />

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import { BRow } from 'bootstrap-vue';
import { BCol } from 'bootstrap-vue';
export default {
  name: "NavAlert",
  components: {
    'b-container': BContainer,
    'b-row': BRow,
    'b-col': BCol
  },
  data () {
    return {
      layout: this.$responsiveLayout,
      content: this.$t(
          'global.safeometer_securityWarning',
          {
            linkPrivacy: this.$t('safeometer_externalLInk_privacy'),
            linkHistory: this.$t('safeometer_externalLInk_deleteHistory'),
            linkClassPrivacy:'mb-link',
            linkClassHistory:'mb-link'
          }
      )
    }
  }
}
</script>

<style scoped>

  .mb-nav-alert-container,
  .mb-nav-alert-row {
    padding: 0;
    margin: 0;
  }

  .mb-nav-alert-container {
    background-color: #FFFFFF;
    width: 100vw;
  }

  .mb-nav-alert{
    background-color: #F9E069;
    color: #494840;
    width: 100%;
  }

  .mb-nav-alert-mobile {
    border-bottom-right-radius: 25px;
    display: table;
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    width: calc(100% - 95px - 90px);
    height: 50px;
    float: left;
  }

  .mb-nav-alert-mobile-content {
    padding: 3px 5px 3px 5px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .mb-nav-alert-spotlight,
  .mb-nav-alert-spotlight-text {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mb-nav-alert-biz,
  .mb-nav-alert-spotlight,
  .mb-nav-alert-spotlight-text {
    vertical-align: middle;
    display: inline-block;
    padding-bottom: 5px;
    padding-top: 5px;
    height: 50px;
  }

  .mb-nav-alert-biz {
    height: 50px;
    display: inline-flex;
    align-items: center;
    padding-bottom: 2px;
    padding-top: 7px;
    padding-right: 10px;
  }

  .mb-nav-alert-spotlight-text {
    font-family: "Nunito Semibold";
    display: inline-flex ;
    align-items: center;
    text-align: right;
    line-height: 10px;
    font-size: 9px;
    color: #FFFFFF;
    width: 55px;
  }

  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-nav-alert-container {
      margin-top: -50px;
      position: fixed;
      z-index: 999;
    }
    .mb-nav-alert-spotlight-text{
      padding-top: 7px;
      color: #0468B1;
    }
  }

</style>