<template>
  <div class="mb-contact-web-site">
    <div class="mb-contact-web-site-label" v-bind:style="{ 'background-color': bgLabelComputed }">
      {{ label }}
    </div>
    <div class="mb-contact-web-site-url" v-bind:style="{ 'background-color': bgWebSiteComputed }"
         v-html="generateHTMLWebSite(shortname, url)"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactWebSite",
  props: ["label", "shortname", "url", "bglabel", "bgwebsite"],
  methods: {
    generateHTMLWebSite(shortName, url){
      return `<a 
        class="mb-contact-web-site-link" 
        style="color: white; text-decoration: underline;"
        href="${ url }">${ shortName }</a>`;
    }
  },
  computed:{
    bgLabelComputed(){
      return this.bglabel;
    },
    bgWebSiteComputed(){
      return this.bgwebsite;
    }
  }
}
</script>

<style scoped>
  .mb-contact-web-site{
    border: 1px solid #0468B1;
  }
  .mb-contact-web-site-url {
    background-color: #0468B1;
    padding: 5px 0;
    font-size: 25px;
    line-height: 30px;
    color: white;
  }
  .mb-contact-web-site-label {
    padding: 10px 0 10px 0;
    line-height: 20px;
    font-size: 24px;
    color:  #0468B1;
  }
  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-contact-web-site-label {
      font-size: 16px;
      line-height: 20px;
    }
    .mb-contact-web-site {
      padding: 3px 0;
      font-size: 22px;
      line-height: 27px;
    }
  }

  .mb-contact-web-site-link,
  .mb-contact-web-site-link:hover{
    color: white;
  }
  .mb-contact-web-site a {
    color: white;
  }

</style>