import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.prototype.$responsiveLayout = {
  desktop: "d-none d-sm-none d-md-none d-lg-block d-xl-block mb-layout-desktop",
  mobile: "d-block d-sm-block d-md-block d-lg-none d-xl-none mb-layout-mobile"
}

if (localStorage.getItem("lang") === null) {
   localStorage.setItem("lang", process.env.VUE_APP_I18N_LOCALE);

 }

if(localStorage.getItem("lang") === 'ar'){
    document.body.classList.add('mb-arabic');
}else{
    document.body.classList.remove('mb-arabic');
}


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
