<template>
  <div>
    <div :class="layout.desktop + ' mb-home-content-header'">
      <div class="mb-home-content-header-msg">
        {{ $t('home.safeometer_home_homeNotSafe') }}
      </div>
      <b-button to="/test" class="mb-home-content-header-btn">
        {{ $t('home.safeometer_home_identifySignals') }}
      </b-button>
    </div>

    <div :class="layout.mobile + ' mb-home-content-header mb-home-content-header-mobile'">
      <div class="mb-home-content-header-msg">
        {{ $t('home.safeometer_home_homeNotSafe') }}
      </div>
      <b-button to="/test" class="mb-home-content-header-btn">
        {{ $t('home.safeometer_home_identifySignals') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: "HomeContentHeader",
  components: {
    'b-button': BButton
  },
  data () {
    return {
      layout: this.$responsiveLayout
    }
  }
}
</script>

<style scoped>
  .mb-home-content-header{
    background-color: #F2F9FF;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .mb-home-content-header-msg{
    font-family: Nunito Semibold;
    margin: 0 auto 10px auto;
    line-height: 20px;
    font-size: 18px;
    color: #0468B1;
    width: 60%;
  }

  .mb-home-content-header-mobile .mb-home-content-header-msg {
    width: 90%;
  }

  .mb-home-content-header-btn {
    font-family: Lora;
    font-weight: bold;
    background-color: #21ACD0;
    border-color: #21ACD0;
    border-radius: 20px;
    padding: 12px 32px 12px 32px;
    line-height: 28px;
    font-size: 22px;
  }

  .mb-home-content-header-mobile {
    background-color: #FFFFFF;
  }

  .mb-home-content-header-mobile .mb-home-content-header-msg{
    color: #0468B1;
  }

  .mb-home-content-header-mobile .mb-home-content-header-btn{
    background-color: #5FDAE1;
    font-weight: bold;
    color: #0468B1;
  }


  @media (min-width: 0px) and (max-width: 991.98px) {
    .mb-home-content-header-msg{
      line-height: 20px;
      font-size: 16px;
    }
    .mb-home-content-header-btn {
      padding: 5px 15px 5px 15px;
      line-height: 23px;
      font-size: 18px;
    }
  }

</style>