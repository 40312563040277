<template>
  <div id="app">
    <Nav></Nav>
    <router-view class="mb-router-view"/>
    <Footer></Footer>
  </div>
</template>

<script>

import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Nav,
    Footer
  }
}

</script>

<style lang="scss">

@font-face {
  font-family: "Lora";
  src: url('assets/fonts/Lora/Lora-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Lora";
  src: url('assets/fonts/Lora/Lora-Italic.ttf') format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Lora";
  src: url('assets/fonts/Lora/Lora-Bold.ttf') format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lora";
  src: url('assets/fonts/Lora/Lora-BoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url('assets/fonts/proxima_ssv/Proxima-Nova-Regular.otf') format("opentype");
}

@font-face {
  font-family: "Nunito Semibold";
  src: url('assets/fonts/Nunito/Nunito-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url('assets/fonts/proxima_ssv/Proxima-Nova-Bold.otf') format("opentype");
}


#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body {
  overflow-x: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.mb-no-padding{
  padding: 0;
}


.mb-router-view {
  position: relative;
  padding-bottom: 25px;
  // Viewport, NarAlert, Nav, Footer
  min-height: calc(100vh - 24px - 83px - 25px);
}

@media (min-width: 0px) and (max-width: 991.98px) {
  .mb-router-view {
    position: relative;
    padding-bottom: 25px;
    // Viewport, NarAlert, Nav, Footer
    min-height: calc(100vh - 45px - 55px - 25px);
  }
}

.mb-arabic{
  direction: rtl;
}

</style>
